import React, { Dispatch, useEffect, useMemo, useRef } from 'react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { TOAST, UPDATE_CART, useBuildYourBundle, useHasMounted, useIsMobile } from '@utils/index';
import {
	Button,
	Container,
	Flex,
	SelectButtons,
	SidebarLayout,
	Title,
	Caption,
	FrameShapeSelect,
	ProductGrid,
	BuildFlowCard,
	Subtotal,
	MyTops,
	FloatingCTA,
	getCartBreakdown,
} from '@components';
import {
	BundleData,
	CartMutationArgs,
	NormalizedBuildFlowCollection,
	NormalizedCollection,
	NormalizedProduct,
	NormalizedVariant,
} from '@ts/index';
import { ADD_TO_CART, BASE_FRAME_NAMES, BUILD_YOUR_OWN_BUNDLE, MEDIUM_LARGE_WIDTH } from '@constants';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext, useToastContext } from '@context';
import { useCart } from '@services/shopify';
import styles from './BuildYourBundle.module.scss';

type BuildYourBundleProps = {
	collection: NormalizedCollection | NormalizedBuildFlowCollection;
	setFrameShape: Dispatch<(typeof BASE_FRAME_NAMES)[number]>;
	frameShape: (typeof BASE_FRAME_NAMES)[number];
};

const BuildYourBundle = ({ collection, setFrameShape, frameShape }: BuildYourBundleProps) => {
	const { handleCartAdd, handleCartReplace } = useCartContext();
	const ctaRef = useRef<HTMLButtonElement>(null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const { push, query } = useRouter();
	const hasMounted = useHasMounted();
	const { showToast } = useToastContext();

	const { data: cart } = useCart();
	const { customBundles } = getCartBreakdown(cart);
	const dataFromBundles = customBundles[query?.bundle as string] as [];
	const editMode = query?.edit === 'true';
	const { handleTop, isFullBundle, priceTops, setSize, size, tops, topsLength, topsDispatch, updatePriceTop, handleBulkAdd } =
		useBuildYourBundle(collection, editMode ? dataFromBundles?.length : 4);

	const refinedTops = dataFromBundles?.reduce((acc, top) => {
		const handle = top['top']['variant']['handle'];
		acc[handle] = top['top']['variant'];
		return acc;
	}, {});

	useEffect(() => {
		if (editMode) {
			handleBulkAdd(refinedTops);
			showToast(TOAST.BUNDLE_EDIT_MODE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sizeButtons = useMemo(() => {
		return [
			{
				label: '3 Tops',
				tag: '10% off',
				onClick: () => {
					topsDispatch({ type: 'newSize', size: 3 });
				},
				selected: size === 3,
				dataTags: { 'data-page-byob-3top': true },
			},
			{
				label: '4 Tops',
				tag: '15% off',
				onClick: () => {
					topsDispatch({ type: 'newSize', size: 4 });
				},
				selected: size === 4,
				dataTags: { 'data-page-byob-4top': true },
			},
		];
	}, [size, topsDispatch, setSize]);

	const handleChangeFrame = (frame: (typeof BASE_FRAME_NAMES)[number]) => {
		setFrameShape(frame);
		topsDispatch({ type: 'reset' });
	};

	const addBundleItemsToCart = async () => {
		const bundleKey = editMode ? (query?.bundle as string) : nanoid();
		const bundle: CartMutationArgs['lineItems'] = Object.values(tops).map((top: NormalizedVariant) => {
			const lineItem = {
				variant: top,
				quantity: 1,
				customAttributes: [
					{ key: '_custom_bundle_key', value: bundleKey },
					{ key: '_Frame', value: frameShape },
					{ key: CART_LINE_ATTRIBUTE_KEYS.COLLECTION_PATH, value: collection.handle },
				],
			};
			return lineItem;
		});

		if (editMode) {
			const lineItemIDs: string[] = dataFromBundles.map((item: BundleData) => item.top.id as string);
			handleCartReplace(lineItemIDs, bundle, true);
		} else {
			handleCartAdd(bundle, false);
		}

		return push({
			pathname: '/cart',
		});
	};

	const myTops = (
		<>
			<div className={styles.copy}>
				<Caption>{BUILD_YOUR_OWN_BUNDLE.myTopsTitle}</Caption>
			</div>
			<Container className={styles.tops}>
				<MyTops
					tops={tops}
					placeholderListLength={size}
					sizeTops='normal'
					color={isMobile ? 'white' : 'gray'}
					onTrashClick={handleTop}
					type='bundle'
				/>
			</Container>
		</>
	);

	return (
		<SidebarLayout.Root>
			<Flex column className={styles.container} fullHeight>
				<Flex column className={styles.title}>
					<Title>{BUILD_YOUR_OWN_BUNDLE.title}</Title>
					<Caption>{BUILD_YOUR_OWN_BUNDLE.subtitle}</Caption>
				</Flex>
				<Flex className={styles.bundle} column>
					<SelectButtons
						buttons={sizeButtons}
						label={BUILD_YOUR_OWN_BUNDLE.selectSize}
						type='bundle'
						typeLabel='large'
						withTag
					/>
					<Flex className={styles.shape} column>
						<Caption>{BUILD_YOUR_OWN_BUNDLE.selectBase}</Caption>
						<FrameShapeSelect
							callback={handleChangeFrame}
							withChevron
							dataTags={{ 'data-click-frame-selector-byob': `The ${frameShape}` }}
						/>
					</Flex>
					{isMobile && hasMounted && <div className={styles.myTops}>{myTops}</div>}

					<Caption className={styles['top-title']}>{BUILD_YOUR_OWN_BUNDLE.selectTop}</Caption>
					{collection && (
						<ProductGrid
							key={`product-grid-${collection.handle}`}
							title={collection.title}
							type='build-flow'
							className={styles.cards}
						>
							{collection.products.map((top: NormalizedVariant | NormalizedProduct) => {
								return (
									<BuildFlowCard
										key={`top-card-${top.handle}`}
										top={updatePriceTop(top.handle, size)}
										onClick={handleTop}
										tops={tops}
										type='bundle'
										disabled={isFullBundle}
									/>
								);
							})}
						</ProductGrid>
					)}
				</Flex>
			</Flex>
			<SidebarLayout.Sidebar>
				<Container pad={4} borderRadius={4} className={styles.sidebar}>
					{!isMobile && hasMounted && myTops}
					<SidebarLayout.CTA className={styles.cta}>
						<>
							<Subtotal subtotal={isFullBundle ? priceTops : `${topsLength}/${size} Tops Added`} />
							<Button
								onClick={addBundleItemsToCart}
								ref={ctaRef}
								fullWidth
								label={editMode ? UPDATE_CART : ADD_TO_CART}
								disabled={!isFullBundle}
								data-add-to-cart={`${size} top`}
							/>
						</>
					</SidebarLayout.CTA>
				</Container>
				<FloatingCTA
					ctaRef={ctaRef}
					name={`${size} Tops Bundle`}
					subtotal={isFullBundle ? priceTops : `${topsLength}/${size} Tops Added`}
					minDigits={2}
					maxDigits={2}
					buttonProps={{
						availableForSale: isFullBundle,
						dataTags: { 'data-add-to-cart': `${size} top` },
						label: editMode ? UPDATE_CART : ADD_TO_CART,
						onClick: addBundleItemsToCart,
					}}
				/>
			</SidebarLayout.Sidebar>
		</SidebarLayout.Root>
	);
};

export default BuildYourBundle;
