/**
 *
 * @param discount e.g. 0.1 for 10% discount
 * @param price
 * @returns
 */
export const calculateDiscount = (discount: number, price: number) => {
	const a = price - price * discount;
	return a;
};
