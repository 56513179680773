import React from 'react';
import cn from 'classnames';
import { Caption, LabelText } from '@components';
import variables from '@styles/export.module.scss';
import { Dict } from '@ts/general';
import Flex from '../Flex';
import Button from '../Button';
import Lozenge from '../Lozenge';
import styles from './SelectButtons.module.scss';

type SelectButtonsProps = {
	label: string;
	type: 'bundle' | 'material';
	typeLabel?: 'small' | 'large';
	buttons: {
		label: string;
		tag?: string;
		onClick: () => void;
		selected: boolean;
		dataTags?: Dict;
	}[];
	className?: string;
	grayButton?: boolean;
	withTag?: boolean;
};

const SelectButtons = ({
	buttons,
	className,
	grayButton = false,
	label,
	typeLabel = 'small',
	type = 'material',
	withTag = false,
}: SelectButtonsProps) => {
	if (!buttons.length) {
		return null;
	}

	return (
		<Flex className={cn(className, { [styles.container]: type == 'bundle' })} column>
			{typeLabel == 'large' && <Caption>{label}</Caption>}
			{typeLabel == 'small' && <LabelText>{label}</LabelText>}
			<Flex className={cn(styles.buttons, { [styles['buttons--with-tag']]: withTag })}>
				{buttons.map(({ label, tag, onClick, selected, dataTags }, index) => (
					<div key={`select-button-${index}`} className={cn(styles.button)} {...dataTags}>
						<Button
							color='white'
							fullWidth
							onClick={onClick}
							label={label}
							removeEffects
							extraClasses={cn({ [styles['selected']]: selected, [styles['gray']]: !selected && grayButton })}
						/>
						{tag && (
							<Lozenge extraClasses={styles.lozenge} shape={'square'} backgroundColor={variables.orange1}>
								<LabelText>{tag}</LabelText>
							</Lozenge>
						)}
					</div>
				))}
			</Flex>
		</Flex>
	);
};

export default SelectButtons;
